var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"users-page"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{attrs:{"lg":10,"md":10,"xs":24}},[_c('h2',{staticClass:"list-title"},[_c('font-awesome-icon',{staticClass:"anticon list-icon",attrs:{"icon":['fas', 'users']}}),_c('span',[_vm._v(_vm._s(_vm.labels.title))])],1),_c('p',{staticClass:"list-subtitle"},[_vm._v(_vm._s(_vm.labels.subtitle))])]),_c('a-col',{attrs:{"lg":14,"md":14,"xs":24}},[_c('a-row',{style:({
          alignItems: 'flex-end',
          marginTop: '25px',
          marginBottom: '10px',
        }),attrs:{"type":"flex","justify":"end","gutter":16}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-input-search',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.labels.placeholderSearch,"allowClear":""},on:{"search":_vm.handleSearch}})],1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('StandardTable',_vm._b({ref:"standardTable",attrs:{"fetchService":_vm.fetchUsers,"columns":_vm.columns,"locale":_vm.locale,"handleShowTotal":_vm.handleShowTotal,"paginationType":_vm.isMobile ? 'arrows' : 'numbers',"customRow":_vm.handleCustomRow}},'StandardTable',!_vm.isMobile && { scroll: { x: true } },false))],1)],1),_c('a-modal',{attrs:{"visible":_vm.modalOpen,"title":_vm.labels.paymentLink.title,"footer":null},on:{"cancel":_vm.resetForm}},[(_vm.modalOpen)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ validate }){return _c('a-form',{attrs:{"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();validate().then(_vm.handleGeneratePaymentLink)}}},[_c('Input',{attrs:{"value":`${_vm.form.user?.firstname || ''} ${
              _vm.form.user?.lastname || ''
            }`.trim(),"rules":"required","vid":"user","name":"user","placeholder":_vm.labels.paymentLink.user.placeholder,"size":"large","allowClear":true,"label":_vm.labels.paymentLink.user.label,"readOnly":true}}),_c('Input',{attrs:{"type":"number","rules":"required|numeric|minNumber:100","min":100,"vid":"amount","name":"amount","placeholder":_vm.labels.paymentLink.amount.placeholder,"size":"large","allowClear":true,"label":_vm.labels.paymentLink.amount.label},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"frequent-amounts-container"},_vm._l(([600, 1200, 2400, 3600, 5000]),function(amount,key){return _c('a-button',{key:key,staticClass:"btn-frequent-amount",attrs:{"type":"primary","size":"large","ghost":""},on:{"click":function($event){return _vm.setAmount(amount)}}},[_vm._v(_vm._s(_vm._f("numberFormat")(amount)))])}),1)]},proxy:true}],null,true),model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.submitting,"disabled":_vm.submitting,"block":"","size":"large"}},[_vm._v(" "+_vm._s(_vm.labels.paymentLink.generate)+" ")])],1)}}],null,false,2747710598)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }