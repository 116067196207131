<template>
  <div class="users-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            class="anticon list-icon"
            :icon="['fas', 'users']"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="16"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
          }"
        >
          <a-col :xs="24" :md="12">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchUsers"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          v-bind="!isMobile && { scroll: { x: true } }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
          :customRow="handleCustomRow"
        />
      </a-col>
    </a-row>

    <!-- PAYMENT LINK MODAL -->
    <a-modal
      :visible="modalOpen"
      :title="labels.paymentLink.title"
      :footer="null"
      @cancel="resetForm"
    >
      <ValidationObserver v-if="modalOpen" ref="observer">
        <a-form
          layout="vertical"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleGeneratePaymentLink)"
        >
          <Input
            :value="
              `${form.user?.firstname || ''} ${
                form.user?.lastname || ''
              }`.trim()
            "
            rules="required"
            vid="user"
            name="user"
            :placeholder="labels.paymentLink.user.placeholder"
            size="large"
            :allowClear="true"
            :label="labels.paymentLink.user.label"
            :readOnly="true"
          />
          <Input
            v-model="form.amount"
            type="number"
            rules="required|numeric|minNumber:100"
            :min="100"
            vid="amount"
            name="amount"
            :placeholder="labels.paymentLink.amount.placeholder"
            size="large"
            :allowClear="true"
            :label="labels.paymentLink.amount.label"
          >
            <template #append>
              <div class="frequent-amounts-container">
                <a-button
                  v-for="(amount, key) in [600, 1200, 2400, 3600, 5000]"
                  type="primary"
                  :key="key"
                  class="btn-frequent-amount"
                  @click="setAmount(amount)"
                  size="large"
                  ghost
                  >{{ amount | numberFormat }}</a-button
                >
              </div>
            </template>
          </Input>
          <a-button
            type="primary"
            html-type="submit"
            :loading="submitting"
            :disabled="submitting"
            block
            size="large"
          >
            {{ labels.paymentLink.generate }}
          </a-button>
        </a-form>
      </ValidationObserver>
    </a-modal>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  maskDni,
  roles,
} from "../../../utils/utils";
import { ValidationObserver } from "vee-validate";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import Input from "../../../components/core/VeeValidateForm/Input";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import accounting from "accounting";

const initialState = {
  user: null,
  amount: null,
};

export default {
  components: { StandardTable, ValidationObserver, Input },
  name: "UserList",
  metaInfo: {
    title: adminPageTitle(labels.users.title),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
    numberFormat(value, precision = 0, thousand = ",", decimal = ".") {
      return accounting.formatNumber(value, precision, thousand, decimal);
    },
  },
  data() {
    return {
      labels: labels.users,
      search: "",
      modalOpen: false,
      activeRow: null,
      submitting: false,
      form: _.cloneDeep(initialState),
    };
  },
  methods: {
    ...mapActions("users", ["fetchUsers", "deleteUser"]),
    ...mapActions("shortUrl", ["generateShortUrl"]),
    resetForm() {
      this.modalOpen = false;
      this.form = _.cloneDeep(initialState);
    },
    setAmount(amount) {
      this.form.amount = amount;
    },
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "delete",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteUser(id);
            this.$message.success(labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                labels.deleteError
            );
          }
        },
      });
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
    async handleGeneratePaymentLink() {
      try {
        this.submitting = true;
        const { user, amount } = this.form;
        const { data } = await this.generateShortUrl({
          user: {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
          },
          amount,
        });
        this.resetForm();
        this.$nextTick(() => {
          this.$success({
            class: "payment-link-modal",
            title: this.labels.paymentLink.success,
            closable: true,
            keyboard: false,
            maskClosable: false,
            okText: this.labels.paymentLink.okText,
            onOk: () => {
              this.$copyText(data.uri);
              this.$message.success(this.labels.paymentLink.copied);
              this.resetForm();
            },
            content: (
              <div>
                <h4>{this.labels.paymentLink.successMessage}</h4>
                <div class={["payment-link"]}>
                  <a href={data.uri} target="_blank">
                    {data.uri}
                  </a>
                  <a-icon
                    type="copy"
                    onClick={() => {
                      this.$copyText(data.uri);
                      this.$message.success(this.labels.paymentLink.copied);
                    }}
                  />
                </div>
              </div>
            ),
          });
        });
      } catch (error) {
        console.error("error", error);
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message ||
            this.labels.paymentLink.error
        );
      } finally {
        this.submitting = false;
      }
    },
    paymentLinkForm(record) {
      this.modalOpen = true;
      this.$nextTick(() => {
        this.form.user = record;
      });
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_USERS_DELETE]);
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.identification_card,
          dataIndex: "identification_card",
          key: "identification_card",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${maskDni(text)}`;
          },
        },
        {
          title: this.labels.table.columns.user,
          dataIndex: "firstname",
          key: "firstname",
          sorter: true,
          hidden: this.isMobile,
          customRender: (_, record) => {
            return `${record.firstname} ${record.lastname}`;
          },
        },
        {
          title: this.labels.table.columns.email,
          dataIndex: "email",
          key: "email",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.commission,
          dataIndex: "commission_percentage",
          key: "commission_percentage",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text ? `${text}%` : "N/A";
          },
        },
        {
          title: this.labels.table.columns.balance,
          dataIndex: "balance",
          key: "balance",
          sorter: true,
          hidden: this.isMobile,
          customRender: (_, record) => {
            return `${this.$options.filters.formatMoney(
              record.balance,
              "$ ",
              2,
              ",",
              "."
            )}`;
          },
        },
        {
          title: this.labels.table.columns.createdAt,
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY H:mm:ss A");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={text ? "green" : "red"}>
                {text
                  ? this.labels.table.columns.status.active
                  : this.labels.table.columns.status.inactive}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (text, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.users.edit",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="delete"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.id)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
                <a-button
                  type="link"
                  class="action-button-payment-link"
                  onClick={() => this.paymentLinkForm(record)}
                  title={this.labels.table.columns.action.paymentLink}
                >
                  <font-awesome-icon
                    icon={["fas", "dollar-sign"]}
                    class="anticon"
                  />
                </a-button>
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.users,
          dataIndex: "lotteries",
          key: "lotteries",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = record.status ? "#28a745" : "#f50";
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.created_at).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH_YEAR
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.created_at)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-fullname">
                      <span>{record.fullname}</span>
                    </div>
                    {record.identification_card && (
                      <div class="list-item-head-content-identification-card">
                        <span>{maskDni(record.identification_card)}</span>
                      </div>
                    )}
                    <div
                      class="list-item-head-content-email"
                      title={record.email}
                    >
                      <span>{record.email}</span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-balance">
                      <span class="success">
                        <font-awesome-icon
                          icon={["fas", "dollar-sign"]}
                          class="anticon"
                        />{" "}
                        {accounting.formatNumber(
                          record.won_total_amount,
                          2,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                    <div class="list-item-head-footer-status">
                      <a-tag color={record.status ? "green" : "red"}>
                        {record.status
                          ? this.labels.table.columns.status.active
                          : this.labels.table.columns.status.inactive}
                      </a-tag>
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <router-link
                        class="edit-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "admin.users.edit",
                          params: { id: record.id },
                        }}
                        title={this.labels.table.columns.action.edit}
                      >
                        {this.labels.table.columns.action.edit}
                      </router-link>
                      {this.canDelete && (
                        <a-button
                          class="delete-button ant-btn ant-btn-danger"
                          onClick={() => this.confirmDelete(record.id)}
                          title={this.labels.table.columns.action.delete}
                          type="danger"
                        >
                          {this.labels.table.columns.action.delete}
                        </a-button>
                      )}
                      <a-button
                        class="payment-link-button ant-btn"
                        onClick={() => this.paymentLinkForm(record)}
                        title={this.labels.table.columns.action.paymentLink}
                        type="primary"
                        ghost
                      >
                        {this.labels.table.columns.action.paymentLink}
                      </a-button>
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.users-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 12px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 60%;
        width: 60%;
        overflow: hidden;
        &-fullname {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-email,
        &-identification-card {
          span {
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 15%;
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-amount {
          width: 100%;
          span {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            &.success {
              color: color(success);
            }
            .anticon {
              margin-right: 6px;
            }
          }
          &:first-child {
            span {
              font-size: 16px;
            }
          }
        }
        &-status {
          width: 100%;
          span {
            font-size: 12px;
            font-weight: 400;
          }
          .ant-tag {
            padding: 0;
            margin: 0;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .edit-button,
        .delete-button {
          width: 100%;
        }
      }
    }
  }
}

.frequent-amounts-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
  max-width: 100%;
  flex-wrap: wrap;
  .btn-frequent-amount {
    width: 100%;
    font-weight: 600;
    @media (min-width: 768px) {
      width: 80px;
    }
  }
}
.payment-link-modal {
  .payment-link {
    width: 100%;
    padding: 5px 10px;
    padding-right: 40px;
    border: 1px dashed color(muted);
    border-radius: 4px;
    position: relative;
    a {
      color: color(primary);
      font-weight: 500;
      font-size: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
    .anticon {
      cursor: pointer;
      font-size: 20px;
      color: color(primary);
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
